<template>
  <div class="testdrive">
    <div class="content">
      <!-- 背景图 -->
      <div class="img-back">
        <img src="/static/home/testdrive.png" alt="" />
        <div class="test-box">
          <div class="test-box-title">预约试驾</div>
          <div class="test-box-input-wraper">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </div>
          <div class="test-box-input-wraper">
            <el-input
              type="number"
              v-model="form.phone"
              placeholder="请输入手机号"
            ></el-input>
          </div>
          <div class="test-box-info">
            悦心车服务顾问会根据试驾车当前预约状况，与您联系确认 具体试驾时间
          </div>
          <el-button class="btn" type="primary" @click="submit">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addOrderRecForPc } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      form: {
        phone: "",
        name: "",
      },
    };
  },
  mounted() {},
  methods: {
    submit() {
      if (!this.form.name) {
        this.$message({
          message: "请填写姓名",
          type: "info",
        });
        return;
      }
      if (!this.form.phone) {
        this.$message({
          message: "请填写手机号",
          type: "info",
        });
        return;
      }
      const reg = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
      if (!reg.test(this.form.phone)) {
        return this.$message.info("请输入正确的手机号");
      }
      addOrderRecForPc({
        mobile: this.form.phone,
        name: this.form.name,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.form = {
            phone: "",
            name: ""
          };
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.testdrive {
  width: 100%;
  .content {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .img-back {
      width: 100%;
      margin: 0 auto;
      position: relative;
      img {
        width: 100%;
        display: inherit;
      }
      .test-box {
        position: absolute;
        left: 20%;
        top: 10%;
        width: 510px;
        padding: 36px 40px;
        background: rgba(11, 10, 10, 0.7);
        .test-box-title {
          font-size: 26px;
          color: #ffffff;
        }
        .test-box-input-wraper {
          margin-top: 40px;
          border-bottom: 1px solid #e5e5e5;
          /deep/ .el-input {
            width: 100%;
            height: 66px;
            .el-input__inner {
              background-color: rgba(0, 0, 0, 0) !important;
              height: 66px;
              border: none;
              color: #fff;
              padding: 0;
              font-size: 20px;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        .test-box-info {
          font-size: 16px;
          width: 100%;
          color: #919191;
          margin-top: 60px;
        }
        .btn {
          width: 100%;
          margin-top: 60px;
          margin-bottom: 20px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
